<template>
  <b-card no-body>
    <!-- Table Top -->
    <b-row class="justify-content-between m-2">
      <b-col
        cols="12"
        md="3"
        class="d-flex align-items-center justify-content-start mb-md-0 px-0"
      >
        <b-form-input
          @input="debounceInput"
          v-model="pagination.search"
          class="d-inline-block"
          placeholder="Search..."
        />
      </b-col>
      <!-- Per Page -->
      <b-col cols="12" md="3">
        <div class="d-flex align-items-center justify-content-end"></div>
      </b-col>
    </b-row>

    <div class="mb-2 d-flex align-items-center justify-content-between">
      <b-table
        primary-key="uid"
        :fields="fields"
        :items="historyList"
        :sort-by.sync="pagination.orderBy"
        :sort-desc.sync="pagination.sortDesc"
        empty-text="No data to show"
      >
        <template #cell(role)="row">
          <b-avatar
            class="mx-1"
            size="32"
            :src="row.item.profileImage"
            variant="info"
          />
          {{ row.item.role }}
        </template>

        <template #cell(actions)="row" style="max-width: 40px;">
          <chevron-down-icon
            v-if="row.detailsShowing"
            size="1.5x"
            class="custom-class cursor-pointer"
            @click="row.toggleDetails"
          ></chevron-down-icon>
          <chevron-up-icon
            size="1.5x"
            class="custom-class cursor-pointer"
            v-if="!row.detailsShowing"
            @click="getSendMyCVHistory(row)"
          >
          </chevron-up-icon>
        </template>

        <template #row-details="row">
          <b-row
            v-if="loading"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >
            <b-spinner label="Loading..."></b-spinner>
          </b-row>
          <b-card v-if="!loading">
            <b-table
              sticky-header="300px"
              show-empty
              empty-text="No data to show"
              :items="row.item.details"
              :fields="fieldsDetails"
            >
              <template #cell(emailBody)="row">
                <p class="too-long-desc" :id="row.item.emailBody">
                  {{ row.item.emailBody }}
                </p>
                <b-tooltip
                  v-if="row.item.emailBody.toString().length > 100"
                  :target="row.item.emailBody"
                  title="Tooltip title"
                  triggers="hover"
                >
                  {{ row.item.emailBody }}
                </b-tooltip>
              </template>

              <template #cell(skippedRecruiters)="row">
                <b-badge
                  variant="light-info"
                  class="text-capitalize mx-sm-1"
                  v-for="rec in row.item.skippedRecruiters"
                >
                  {{ rec.name }}
                </b-badge>
              </template>
              <template #cell(selectedIndustries)="row">
                <b-badge
                  variant="light-primary"
                  class="text-capitalize mx-sm-1"
                  v-for="indu in row.item.selectedIndustries"
                >
                  {{ indu.name }}
                </b-badge>
              </template>
              <template #cell(totalSent)="row">
                {{
                  row.item.recipientRecruiters.filter((item) => item.isSent)
                    .length
                }}
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>
    </div>
    <div class="mx-2 mb-2">
      <b-row cols="12">
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <label>Show</label>
          <v-select
            v-model="pagination.pageSize"
            :options="pagination.perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>
        <b-col
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.totalCount"
            :per-page="pagination.pageSize"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import sendMyCVHistory from "@/http/sendMyCVHistory";
import ArrowDownIcon from "vue-feather-icons/icons/ArrowDownIcon";
import ArrowUpIcon from "vue-feather-icons/icons/ArrowUpIcon";
import ChevronDownIcon from "vue-feather-icons/icons/ChevronDownIcon";
import ChevronUpIcon from "vue-feather-icons/icons/ChevronUpIcon";
import boolean from "vue-good-table/src/components/types/boolean";
import vSelect from "vue-select";
import { debounce } from "vue2-leaflet";

export default {
  components: {
    BMedia,
    BAvatar,
    BBadge,
    BSpinner,
    BTooltip,
    BFormInput,
    vSelect,
    ChevronUpIcon,
    ChevronDownIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    BCol,
    BRow,
    BPagination,
    BTable,
    BButton,
    BCard,
  },
  data() {
    return {
      loading: boolean,
      fields: [
        { key: "actions", label: "" },
        { key: "role", sortable: true },
        { key: "name", sortable: true },
        { key: "title", sortable: true },
      ],
      fieldsDetails: [
        { key: "emailSubject", sortable: false },
        { key: "emailBody", sortable: false },
        { key: "skippedRecruiters", sortable: false },
        { key: "selectedIndustries", sortable: false },
        { key: "totalSent", sortable: false },
      ],
      historyList: [],
      allOpenRows: [],
      pagination: {
        sortDesc: true,
        orderType: "desc",
        orderBy: undefined,
        pageSize: 10,
        page: 1,
        totalCount: 0,
        search: null,
        perPageOptions: [10, 25, 50, 100],
      },
    };
  },
  watch: {
    "pagination.page"() {
      this.getSentHistory();
    },
    "pagination.pageSize"() {
      this.getSentHistory();
    },
    "pagination.orderBy"() {
      this.getSentHistory();
    },
    "pagination.sortDesc"() {
      if (this.pagination.sortDesc) {
        this.pagination.orderType = "desc";
      } else {
        this.pagination.orderType = "asc";
      }
      this.getSentHistory();
    },
  },
  methods: {
    getSentHistory() {
      sendMyCVHistory.getSentHistory(this.pagination).then(
        (response) => {
          this.historyList = response.results;
          this.pagination.totalCount = response.totalCount;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getSendMyCVHistory(row) {
      row.toggleDetails();
      this.loading = true;
      sendMyCVHistory.getSendMyCVHistory(row.item.uid).then((response) => {
        row.item.details = response;
        this.loading = false;
      });
    },
  },
  created() {
    this.getSentHistory();
    this.debounceInput = debounce(() => {
      this.pagination.page = 1;
      this.getSentHistory();
    }, 300);
  },
};
</script>

<style lang="scss">
.too-long-desc {
  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
